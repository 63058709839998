@import './fonts.css';

.qrscanner > section {
  width: 100%;
  height: 100%;
  padding-top: 0 !important;
}

.qrscanner video {
  height: 100vh !important;
}

input:focus {
  outline: none;
}
