@font-face {
  font-family: 'ProximaNova-Black';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Black.ttf');
}
@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Bold.ttf');
}
@font-face {
  font-family: 'ProximaNova-Extrabld';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Extrabld.ttf');
}
@font-face {
  font-family: 'ProximaNova-Light';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Light.ttf');
}
@font-face {
  font-family: 'ProximaNova-LightIt';
  src: url('../assets/fonts/ProximaNova/ProximaNova-LightIt.ttf');
}
@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Regular.ttf');
}
@font-face {
  font-family: 'ProximaNova-RegularIt';
  src: url('../assets/fonts/ProximaNova/ProximaNova-RegularIt.ttf');
}
@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Semibold.ttf');
}
@font-face {
  font-family: 'ProximaNova-SemiboldIt';
  src: url('../assets/fonts/ProximaNova/ProximaNova-SemiboldIt.ttf');
}

@font-face {
  font-family: 'UbuntuMono-Bold';
  src: url('../assets/fonts/UbuntuMono/UbuntuMono-Bold.ttf');
}
@font-face {
  font-family: 'UbuntuMono-BoldItalic';
  src: url('../assets/fonts/UbuntuMono/UbuntuMono-BoldItalic.ttf');
}
@font-face {
  font-family: 'UbuntuMono-Italic';
  src: url('../assets/fonts/UbuntuMono/UbuntuMono-Italic.ttf');
}
@font-face {
  font-family: 'UbuntuMono-Regular';
  src: url('../assets/fonts/UbuntuMono/UbuntuMono-Regular.ttf');
}
